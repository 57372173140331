import React, { useState } from "react";
import "./header.css";
import { Link } from "react-router-dom";

const Header = () => {
  const [mobile, setMobile] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({
    nombre: "",
    numero: "",
    correo: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSubscriptionData({ ...subscriptionData, [name]: value });
  };

  const enviarPorWhatsApp = () => {
    // Construir el enlace de WhatsApp con los datos del usuario
    const { nombre, numero, correo } = subscriptionData;
    const mensaje = `Hola, soy ${nombre}. Mi número es ${numero} y mi correo es ${correo} y estoy interesado en suscribirme en los grupos de noticias de ABCupon`;
    const enlaceWhatsApp = `https://wa.me/62558356/?text=${encodeURIComponent(mensaje)}`;
    
    // Redirigir a WhatsApp
    window.location.href = enlaceWhatsApp;
  };


  return (
    <>
      <header>
        <div className="container flexSB">
          <nav className="flexSB">
          <div className='logo'>
          <Link to="/">
                <img src="./images/logo.png" alt="" />
              </Link>
            </div>
            <ul className={mobile ? "navMenu-list" : "flexSB"} onClick={() => setMobile(false)}>
              <li>
              <a href="https://abcupon.com/">ABCupon</a>
              </li>
              <li>
                <Link to="/">Noticias</Link>
              </li>
              <li>
              <a href="https://abcupon.com/#/contact">Contacto</a>
              </li>
            </ul>
            <button className="toggle" onClick={() => setMobile(!mobile)}>
              {mobile ? <i className="fa fa-times"></i> : <i className="fa fa-bars"></i>}
            </button>
          </nav>
          <div className="account flexSB">
            <button onClick={() => setShowModal(true)}>Suscríbase ahora</button>
          </div>
        </div>
      </header>

      {/* Modal de suscripción */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="modal-close" onClick={() => setShowModal(false)}>
              &times;
            </span>
            <h2>Suscríbase</h2>
            <input
              type="text"
              name="nombre"
              placeholder="Nombre"
              value={subscriptionData.nombre}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="numero"
              placeholder="Número"
              value={subscriptionData.numero}
              onChange={handleInputChange}
            />
            <input
              type="email"
              name="correo"
              placeholder="Correo Electrónico"
              value={subscriptionData.correo}
              onChange={handleInputChange}
            />
            <button className="whatsapp-button" onClick={enviarPorWhatsApp}>
               <i className="fa fa-whatsapp"></i> WhatsApp
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
