import React from "react";
import { Link } from "react-router-dom";

const Ucard = ({ item: { id, cover, name, url } }) => {
  const abrirURL = () => {
    if (url) {
      // Si hay una URL, abre en una nueva pestaña
      window.open(url, "_blank");
    } else {
      // Si no hay una URL, redirige a la página singlepage
      window.location.href = `/singlepage/${id}`;
    }
  };

  // Clase CSS condicional para el tamaño de fuente en pantallas pequeñas
  const claseTextoPequeño = window.innerWidth <= 768 ? "texto-pequeño" : "";

  return (
    <div className='MovieBox' style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div className='text' style={{ marginBottom: "10px" }}>
        <h3 className={claseTextoPequeño}>{name}</h3>
        {/* Eliminé los elementos de tiempo y URL */}
        <br /><br />
        <button className='primary-btn' onClick={abrirURL}>
          <i className='fa fa-play'></i> Expandir
        </button>
      </div>
      <div className='img'>
        <img src={cover} alt='' style={{ maxWidth: "100%" }} />
      </div>
    </div>
  );
};

export default Ucard;
