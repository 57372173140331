export const homeData = [
  {
    id: 1,
    name: "¿Que paso con el robo del BN?",
    rating: 4.7,
    time: "1min : 02s",
    desc: "¿Cómo puede ocurrir que un día, el banco más grande de un país se percata de que le faltan más de ¢3.000 millones (unos $6,2 millones) en billetes que deberían estar en sus propias oficinas? A comienzos de octubre pasado, eso le pasó al Banco Nacional de Costa Rica (BNCR).",
    starring: "Rodney Zuñiga",
    genres: "ABCupon",
    tags: "Noticias, Finanzas, Nueva",
    cover: "../images/captura.jpg",
    video: "../video/BancoNacional.mp4",
    date: "23-1-2024",
  },
  {
    id: 2,
    name: "¿Como se combate criminalidad en Costa Rica?",
    rating: 4.6,
    time: "2hr : 22mins",
    desc: "San José, 23 ene (EP/PL).- Un paquete de diez proyectos dirigidos a enfrentar el crimen organizado y el narcotráfico que conduce a la ola de homicidios que vive el país fue presentado hoy al presidente de Costa Rica, Rodrigo Chaves. En un encuentro en Casa Presidencial, el presidente de la Asamblea Legislativa Rodrigo Arias y del Poder Judicial Orlando Aguirre dieron a conocer al Ejecutivo en qué consisten los nuevos proyectos, su contenido y la ruta a seguir para la aprobación de manera ágil en la Asamblea Legislativa.",
    starring: "Brenda Chapman, Jeff Nathanson",
    genres: "Adventures",
    tags: "Adventures,Animation,Family",
    cover: "../images/criminalidad.jpg",
    video: "../video/criminalidad.mp4",
    date: "10-JUL-2021",
  },
  {
    id: 3,
    name: "¿En busca de casa? Red de Hipotecas le financia hasta el 100%",
    rating: 4.8,
    time: "2hr : 22mins",
    desc: "La oferta de red de hipotecas se basa en el credito privado, incluye diferentes líneas de financiamiento o incluso el 100% de la propiedad para que la prima no sea ningún obstaculo para las familias, con opciones tanto en colones como dólares.",
    starring: "James Chinlund, Jeff Nathanson",
    genres: "Adventures",
    tags: "Adventures,Action ",
    cover: "../images/red_hipotecas.jpg",
    video: "../video/red_hipotecas.mp4",
    date: "20-FEB-2010",
  },
 
]
export const upcome = [
  {
    id: 1,
    cover: "../images/upcome/abcupon.jpg",
    name: "ABCupon.com",
    time: "2hr : 38mins",
    url: "https://www.facebook.com/ComunidadABCupon/",
  },
  {
    id: 2,
    cover: "../images/upcome/directorio.png",
    name: "Directorio Comercial",
    time: "2hr : 38mins",
    url: "https://www.facebook.com/abcupon0/",
  },
  {
    id: 3,
    cover: "../images/upcome/directorio_cooperativas.jpg",
    name: "Directorio de Cooperativas",
    time: "2hr : 38mins",
    url: "https://www.facebook.com/directoriodecooperativascr /",
  },
  {
    id: 4,
    cover: "../images/upcome/arrendadora.jpg",
    name: "Arrendadora S.A",
    time: "2hr : 38mins",
    url: "https://www.facebook.com/arrendadoracr/",
  },
  {
    id: 5,
    cover: "../images/upcome/coopered.jpg",
    name: "Coopered R.L",
    time: "2hr : 38mins",
    url: "https://www.facebook.com/CooperedRL",
  },
  {
    id: 6,
    cover: "../images/upcome/credired.jpg",
    name: "Credi Red S.A",
    time: "2hr : 38mins",
    url: "https://www.facebook.com/crediredsa/",
  },
  {
    id: 7,
    cover: "../images/upcome/publiarte.jpg",
    name: "Publiarte",
    time: "2hr : 38mins",
    url: "https://www.facebook.com/publiartecr/",
  },
  {
    id: 8,
    cover: "../images/upcome/redhipotecas.jpg",
    name: "Red de Hipotecas",
    time: "2hr : 38mins",
    url: "https://www.facebook.com/reddehipotecas/",
  },
]
export const latest = [
  {
    id: 1,
    name: "¿Que paso con el robo del BN?",
    rating: 4.7,
    time: "1min : 02s",
    desc: "¿Cómo puede ocurrir que un día, el banco más grande de un país se percata de que le faltan más de ¢3.000 millones (unos $6,2 millones) en billetes que deberían estar en sus propias oficinas? A comienzos de octubre pasado, eso le pasó al Banco Nacional de Costa Rica (BNCR).",
    starring: "Rodney Zuñiga",
    genres: "ABCupon",
    tags: "Noticias, Finanzas, Nueva",
    cover: "../images/captura.jpg",
    video: "../video/BancoNacional.mp4",
    date: "23-1-2024",
  },
  {
    id: 2,
    name: "¿Como se combate criminalidad en Costa Rica?",
    rating: 4.6,
    time: "2hr : 22mins",
    desc: "San José, 23 ene (EP/PL).- Un paquete de diez proyectos dirigidos a enfrentar el crimen organizado y el narcotráfico que conduce a la ola de homicidios que vive el país fue presentado hoy al presidente de Costa Rica, Rodrigo Chaves. En un encuentro en Casa Presidencial, el presidente de la Asamblea Legislativa Rodrigo Arias y del Poder Judicial Orlando Aguirre dieron a conocer al Ejecutivo en qué consisten los nuevos proyectos, su contenido y la ruta a seguir para la aprobación de manera ágil en la Asamblea Legislativa.",
    starring: "Brenda Chapman, Jeff Nathanson",
    genres: "Adventures",
    tags: "Adventures,Animation,Family",
    cover: "../images/criminalidad.jpg",
    video: "../video/criminalidad.mp4",
    date: "10-JUL-2021",
  },
  {
    id: 3,
    name: "¿En busca de casa? Red de Hipotecas le financia hasta el 100%",
    rating: 4.8,
    time: "2hr : 22mins",
    desc: "La oferta de red de hipotecas se basa en el credito privado, incluye diferentes líneas de financiamiento o incluso el 100% de la propiedad para que la prima no sea ningún obstaculo para las familias, con opciones tanto en colones como dólares.",
    starring: "James Chinlund, Jeff Nathanson",
    genres: "Adventures",
    tags: "Adventures,Action ",
    cover: "../images/red_hipotecas.jpg",
    video: "../video/red_hipotecas.mp4",
    date: "20-FEB-2010",
  },
  
]

export const trending = [
  {
    id: 1,
    name: "The Dinosaur",
    rating: 4.7,
    time: "2hr : 22mins",
    desc: "Sand and dust storms (SDS), also known as sirocco, haboob, yellow dust, white storms, and the harmattan, are a natural phenomenon linked with land and water management and climate change.",
    starring: "Karen Gilchrist, James Earl Jones",
    genres: "Action",
    tags: "Action, Adventures, Horror",
    cover: "../images/img/slide1.png",
    date: "20-Jan-1997",
  },
  {
    id: 2,
    name: "The Great Emperor",
    rating: 4.6,
    time: "2hr : 22mins",
    desc: "The island, like its inhabitants, is wholly fictional. Kong's island home in the Pacific makes its first appearance, along with Kong himself, in the 1933 film King Kong. ",
    starring: "Brenda Chapman, Jeff Nathanson",
    genres: "Adventures",
    tags: "Adventures,Animation,Family",
    cover: "../images/img/slide2.jpg",
    date: "10-JUL-2021",
  },
  {
    id: 3,
    name: "Jumbo Queen",
    rating: 4.8,
    time: "2hr : 22mins",
    desc: "Piracy is an act of robbery or criminal violence by ship or boat-borne attackers upon another ship or a coastal area, typically with the goal of stealing cargo and other valuable items or properties. ",
    starring: "James Chinlund, Jeff Nathanson",
    genres: "Adventures",
    tags: "Adventures,Action ",
    cover: "../images/img/slide3.jpg",
    date: "20-FEB-2010",
  },
]
export const recommended = [
  {
    id: 1,
    name: "¿Que paso con el robo del BN?",
    rating: 4.7,
    time: "1min : 02s",
    desc: "¿Cómo puede ocurrir que un día, el banco más grande de un país se percata de que le faltan más de ¢3.000 millones (unos $6,2 millones) en billetes que deberían estar en sus propias oficinas? A comienzos de octubre pasado, eso le pasó al Banco Nacional de Costa Rica (BNCR).",
    starring: "Rodney Zuñiga",
    genres: "ABCupon",
    tags: "Noticias, Finanzas, Nueva",
    cover: "../images/captura.jpg",
    video: "../video/BancoNacional.mp4",
    date: "23-1-2024",
  },
  {
    id: 2,
    name: "¿Como se combate criminalidad en Costa Rica?",
    rating: 4.6,
    time: "2hr : 22mins",
    desc: "San José, 23 ene (EP/PL).- Un paquete de diez proyectos dirigidos a enfrentar el crimen organizado y el narcotráfico que conduce a la ola de homicidios que vive el país fue presentado hoy al presidente de Costa Rica, Rodrigo Chaves. En un encuentro en Casa Presidencial, el presidente de la Asamblea Legislativa Rodrigo Arias y del Poder Judicial Orlando Aguirre dieron a conocer al Ejecutivo en qué consisten los nuevos proyectos, su contenido y la ruta a seguir para la aprobación de manera ágil en la Asamblea Legislativa.",
    starring: "Brenda Chapman, Jeff Nathanson",
    genres: "Adventures",
    tags: "Adventures,Animation,Family",
    cover: "../images/criminalidad.jpg",
    video: "../video/criminalidad.mp4",
    date: "10-JUL-2021",
  },
  {
    id: 3,
    name: "¿En busca de casa? Red de Hipotecas le financia hasta el 100%",
    rating: 4.8,
    time: "2hr : 22mins",
    desc: "La oferta de red de hipotecas se basa en el credito privado, incluye diferentes líneas de financiamiento o incluso el 100% de la propiedad para que la prima no sea ningún obstaculo para las familias, con opciones tanto en colones como dólares.",
    starring: "James Chinlund, Jeff Nathanson",
    genres: "Adventures",
    tags: "Adventures,Action ",
    cover: "../images/red_hipotecas.jpg",
    video: "../video/red_hipotecas.mp4",
    date: "20-FEB-2010",
  },
 
]
