import React from "react"
import { homeData } from "../../dummyData"
import "./footer.css"

const Footer = () => {
  return (
    <>
      <footer>
        <div className='container'>
          <div className='box'>
            <ul className='flex'>

            </ul>
            <p>© © 2023 CREATIVA LABORATORIO SITE WEB ABCupón. Derechos de Autor reservados.</p>
          </div>
          <div className='box'>
            <h3>Siguenos</h3>
            <a href="https://www.facebook.com/abcupon0" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f"></i>
            </a>
            {/* <i className='fab fa-instagram'></i> */}
          </div>
          <div className='box'>
            <h3>Aplicación ABCupon</h3>
            <div className='img flexSB'>
              <img src='https://img.icons8.com/color/48/000000/apple-app-store--v3.png' />
              <span>App Store</span>
              <img src='https://img.icons8.com/fluency/48/000000/google-play.png' />
              <span>Google Play Store</span>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
