import React, { useState, useEffect } from "react";
import "./style.css";
import { useParams, useLocation } from "react-router-dom";
import { homeData, recommended } from "../../dummyData";
import Upcoming from "../upcoming/Upcomming"

const SinglePage = () => {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const location = useLocation();

  useEffect(() => {
    let item = homeData.find((item) => item.id === parseInt(id));
    if (item) {
      setItem(item);
    }
  }, [id]);

  const [rec, setRec] = useState(recommended);

  // Función para compartir por WhatsApp
  const compartirPorWhatsApp = () => {
    const mensaje = `Echa un vistazo a esta noticia: ${item.name} - ${item.desc} ${window.location.href}`;
    const enlaceWhatsApp = `https://wa.me/?text=${encodeURIComponent(mensaje)}`;
    window.open(enlaceWhatsApp, "_blank");
  };

  return (
    <>
      {item ? (
        <>
          <section className="singlePage">
            <div className="singleHeading">
              <h1>{item.name} </h1> <span> HD </span>
            </div>
            <div className="container">
              <video src={item.video} controls></video>
              <div className="para">
                <h3>Date : {item.date}</h3>
                <p>{item.desc}</p>
              </div>
              <div className="soical">
                <h3>Compartir : </h3>
                {/* <img src="https://img.icons8.com/color/48/000000/facebook-new.png" alt="Facebook" /> */}
                <img src="https://img.icons8.com/fluency/48/000000/whatsapp.png" alt="WhatsApp" onClick={compartirPorWhatsApp} />
                {/* <img src="https://img.icons8.com/fluency/48/000000/linkedin-circled.png" alt="LinkedIn" /> */}
              </div>
            </div>
          </section>
          <Upcoming items={rec} title="Otras Noticias" />
        </>
      ) : (
        "no"
      )}
    </>
  );
};

export default SinglePage;
